// JsFromRoutes CacheKey 1e14e50e40629bb66515e3a77d33da45
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  twoFactorQr: /* #__PURE__ */ definePathHelper('get', '/users/two_factor_qr'),
  edit: /* #__PURE__ */ definePathHelper('get', '/users/edit'),
  editUser: /* #__PURE__ */ definePathHelper('get', '/users/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/users/:id'),
  select2: /* #__PURE__ */ definePathHelper('get', '/users/select2'),
}
